import React from "react";
import Fade from 'react-reveal/Fade';


const Home = () => {

    return(<div className="page-wrapper" id='home'>
         <div className="banner-page">
            <div className="banner-background"></div>
            <div className="banner-container">
                <Fade cascade left>
                    <div className="banner-content-text">
                        <h1>BUILDING</h1>
                        <span>TRUST &amp; RELIABILITY</span>
                        <a className='btn btn-orange'>DISCOVER MORE</a>
                    </div>
                </Fade>
            </div>
         </div>
    </div>);

};


export default Home;