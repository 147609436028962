import React from "react";
import mission from '../img/icons/mission.png';
import vision from '../img/vision-icon.png';
import values from '../img/values.png';
import culture from '../img/icons/culture.png';


import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const About = () => {

    return(<div className='page-wrapper page-about' id='about'>
        <div className="pages">
            <div className="title-block">
                <span className="grey-span">ABOUT</span>
                <span className="wexford">wexford<i className="red-dot">.</i></span>
            </div>
            <div className="about-bg">
                <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-4 col-md-4">
                                <Fade left cascade>
                                    <div className="slogan">
                                        <h3>WE</h3>
                                        <h2>BUILD</h2>
                                        <h3>YOUR</h3>
                                        <h2>DREAMS</h2>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-sm-12 col-lg-8 col-md-8">
                                <Fade right cascade>
                                    <p>One of the leading qualified Contractors in U.A.E, Directed by highly qualified, experienced and Technical Team. The company achieved its 
                                    position in the core of contracting business in the U.A.E over a short span of time.<br/><br/> 
                                    We are quality / time conscious contractor and have a proven track record of keeping the time cost factor as our main consideration.<br/><br/>
                                    To execute any relevant project, we are confident that our commitment to quality and service will continue to build our reputation in the contracting 
                                    business.</p>
                                </Fade>
                                
                            </div>
                        </div>
                </div>
            </div>
            <div className="statement-section">
                <section className="mission">
                    <div className="item-left">
                        <div className="img-holder"></div>
                        <div className="overlay"></div>
                    </div>
                    <div className="item-center">
                        
                        <img src={mission} className="img-mission" alt='Wexford Mission Statement'/>

                        <div className="overlay">
                            <div className="desc-container">
                                <img src={mission} className="img-overlay" alt='Wexford Mission Statement'/>
                                <h5>OUR MISSION</h5>
                                <p className="desc">We strive to provide our clients with a level of service that exceeds their expectations and allow our employees the opportunity to grow and prosper. 
                                To instill confidence with all parties by professionally managing all aspects of the project whether it is large or small.</p>
                            </div>
                            
                        </div>
                        
                        
                    </div>
                    <div className="item-right">
                        <div className="img-holder"></div>
                        <div className="overlay"></div>
                    </div>
                </section>
                <section className="values">
                    <div className="item-left">
                        <div className="img-holder"></div>
                        <div className="overlay"></div>
                    </div>
                    <div className="item-center">
                        <img src={values} alt='Wexford Values' className="img-values" />
                        <div className="overlay">
                            <div className="desc-container">
                                <img src={values} className="img-overlay" alt='Wexford Values'/>
                                <h5>OUR CORE VALUES</h5>
                                <ul>
                                    <li>INTEGRITY</li>
                                    <li>INNOVATION</li>
                                    <li>TEAMWORK</li>
                                    <li>ACCOUNTABILITY</li>
                                    <li>EXCELLENCE</li>
                                    <li>RESPECT</li>
                                </ul>
                            </div>
                             {/* <a href='/' className="btn btn-read-more">READ MORE</a> */}
                        </div>
                    </div>
                  
                    <div className="item-right">
                        <div className="img-holder"></div>
                        <div className="overlay"></div>
                    </div>
                </section>
                <section className="vision">
                    <div className="item-left">
                        <div className="img-holder"></div>
                        <div className="overlay"></div>
                    </div>
                    <div className="item-center">
                        <img src={vision} className="img-vision" alt='Wexford Vision Statement'/>
                        <div className="overlay">
                            <div className="desc-container">
                                <img src={vision} className="img-overlay" alt='Wexford Vision Statement'/>
                                <h5>OUR VISION</h5>
                                <p className="desc">We are committed to maintaining and developing our presence as a leading contractor company in the market. Our reputation is built on trust, and we strive to provide our clients with high quality and efficient service.</p>
                            
                            {/* <a href='/' className="btn btn-read-more">READ MORE</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="item-right">
                        <div className="img-holder"></div>
                        <div className="overlay"></div>
                    </div>
                </section>
            </div>
        </div>
    </div>)

};

export default About;