import React  from "react";
import Slider from "react-slick";

const completed = [
    {
        title: 'Ship Building Workshop',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-ship.png'
    },
    {
        title: 'Store Warehouse',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-wh.png'
    },
    {
        title: 'Maintenance Workshop Showroom',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-holdings.png'
    },
    {
        title: 'Restaurant',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-resto.jpeg'
    },
    {
        title: 'EMAAR properties',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/emaar-properties.png'
    },
];

const current = [
    {
        title: 'Ship Building Workshop',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-ship.png'
    },
    {
        title: 'Store Warehouse',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-wh.png'
    },
    {
        title: 'Maintenance Workshop Showroom',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-holdings.png'
    },
    {
        title: 'Restaurant',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-resto.jpeg'
    },
    {
        title: 'EMAAR properties',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/emaar-properties.png'
    },
];

const future = [
    {
        title: 'Ship Building Workshop',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-ship.png'
    },
    {
        title: 'Store Warehouse',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-wh.png'
    },
    {
        title: 'Maintenance Workshop Showroom',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-holdings.png'
    },
    {
        title: 'Restaurant',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/al-fattan-resto.jpeg'
    },
    {
        title: 'EMAAR properties',
        desc: 'Al Fattan Holding Investment\nAl Aseel Engineering Consultant',
        image: '/img/emaar-properties.png'
    },
];

const styles = {
    width: '450px',
    height: '450px',
    display: 'block'
};

const PreviousArrow = (props) => {
    // const {className, style, onClick } = props;
    const {onClick } = props;
    return(<i className='fa fa-chevron-left slick-prev pull-left' onClick={onClick}></i>);
};

const NextArrow = (props) => {
    // const {className, style, onClick } = props;
    const {onClick } = props;
    return(<i className='fa fa-chevron-right slick-next pull-right' onClick={onClick}></i>);
};

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    slidesToScroll: 3,
    centerMode: true,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />, 
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};



const CardProjects = ({project}) => {
    

    return(<Slider {...settings}>
        {
            project === 'completed' ? 
            completed.map((i, index) => (
                <div style={styles} key={index} >
                    <div className="card">
                        <div className="img-container" style={{backgroundImage: `url(${i.image}) `}}></div>
                        <div className="title-bar">
                            <span className="title">{i.title}</span>
                        </div>
                        <div className="overlay">
                            <div className="desc-container">
                                <span className="title">{i.title}</span>
                                <span className="desc">{i.desc}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )) : 
           /*  project === 'current' ? 
            current.map((i, index) => (
                <div style={styles} key={index} >
                    <div className="card">
                        <div className="img-container" style={{backgroundImage: `url(${i.image}) `}}></div>
                        <div className="title-bar">
                            <span className="title">{i.title}</span>
                        </div>
                        <div className="overlay">
                            <div className="desc-container">
                                <span className="title">{i.title}</span>
                                <span className="desc">{i.desc}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )) : */
            future.map((i, index) => (
                <div style={styles} key={index} >
                    <div className="card">
                        <div className="img-container" style={{backgroundImage: `url(${i.image}) `}}></div>
                        <div className="title-bar">
                            <span className="title">{i.title}</span>
                        </div>
                        <div className="overlay">
                            <div className="desc-container">
                                <span className="title">{i.title}</span>
                                <span className="desc">{i.desc}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
    </Slider>);

};


export default CardProjects;