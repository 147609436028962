import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../css/main.scss';

import fb from '../img/fb-logo-f.png';
import insta from '../img/insta-logo.png';
import linkedin from '../img/linkedin-logo.png';
import twitter from '../img/twitter-logo.png';
import whatsapp from '../img/whatsapp-logo.png';
import email from '../img/email-logo.png';
import ytube from '../img/ytube.png';

import Header from '../components/header';
import Home from './home';
import About from './about';
import Services from './services';
import Projects from './projects';
import Team from './team';
import Contacts from './contacts';
import Footer from '../components/footer';
import Fade from 'react-reveal/Zoom';


const Webpages = () => {

    const wamsg = 'https://wa.me/+971556772419/?text=' + encodeURIComponent('Hi, I would like to know more about your services.');

    return(<div className='main-wrapper'>
        <Header/>
        <div className='content-wrapper'>
                <Home />
                <About />
                <Services />
                <Projects />
                {/* <Team /> */}
                <Contacts />
        </div>
        <Footer />
      
        <Fade right cascade>
            <div className='side-widget'>
                <div className='btn-social btn-social--email'>
                    <a href='mailto:info@wxf.ae' target='_blank'>
                        <img src={email} alt='Email us' /> 
                    </a>
                </div>
                <div className='btn-social btn-social--facebook'>
                    <a href='https://www.facebook.com/profile.php?id=100093332565859' target='_blank'>
                        <img src={fb} alt='Wexford Official Facebook Account' /> 
                    </a>
                </div>
                <div className='btn-social btn-social--insta'>
                    <a href='https://instagram.com/wexford_contracting?igshid=MzRlODBiNWFlZA==' target='_blank'>
                        <img src={insta} alt='Wexford Official Instagram Account' /> 
                    </a>
                </div>
                {/* <div className='btn-social btn-social--ytube'>
                    <a href='/' target='_blank'>
                        <img src={ytube} alt='Wexford Official Youtube Account' /> 
                    </a>
                </div> */}
                {/* <div className='btn-social btn-social--twitter'>
                    <a href='/' target='_blank'>
                        <img src={twitter} alt='Wexford Official Twitter Account' /> 
                    </a>
                </div>
                <div className='btn-social btn-social--linkedin'>
                    <a href='/' target='_blank'>
                        <img src={linkedin} alt='Wexford Official LinkedIn Account' /> 
                    </a>
                </div> */}
                <div className='btn-social btn-social--whatsapp'>
                    <a href={wamsg} target='_blank'>
                        <img src={whatsapp} alt='Wexford Official Whatsapp Account' /> 
                    </a>
                </div>
            </div>
        </Fade>
    </div>);
};


export default Webpages;