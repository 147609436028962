import React from "react";
import Slider from 'react-slick';
import reportage from '../img/partners/reportage-properties.png';
import alqudra from '../img/partners/al-qudra.png';
import trojan from '../img/partners/trojan.png';
import emaar from '../img/partners/emaar.png';
import alfattan from '../img/partners/al-fattan.png';
import tawazun from '../img/partners/tawazun.png';
import adaf from '../img/partners/adaf.png';
import adports from '../img/partners/abu-dhabi-ports.png';


/* const items = [
    {
        image: reportage,
        alt: 'Reportage Properties'
    },
    {
        image: alqudra,
        alt: 'Al Qudra Holdings'
    },
    {
        image: trojan,
        alt: 'Trojan Holdings'
    },
    {
        image: emaar,
        alt: 'Emaar Properties'
    },
    {
        image: alfattan,
        alt: 'Al Fattan'
    },
    {
        image: tawazun,
        alt: 'Tawazun'
    },
    {
        image: adaf,
        alt: 'Abu Dhabi Armed Forces'
    },
    {
        image: adports,
        alt: 'Abu Dhabi Ports'
    },
    
]; */

const styles = {
    width: '200px',
    height: '200px',
    display: 'block'
};

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    autoplay: true,
    slidesToScroll: 5,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};


const Partners  = () => {
    
        return(<Slider {...settings}>
            <div style={styles}>
                <div className="card">
                    <img src={reportage} alt='Reportage Properties' />
                </div>
            </div>
            <div style={styles}>
                <div className="card">
                    <img src={alqudra} alt='Al Qudra Properties' />
                </div>
            </div>
            <div style={styles}>
                <div className="card">
                    <img src={trojan} alt='Trojan Holdings' />
                </div>
            </div>
            <div style={styles}>
                <div className="card">
                    <img src={emaar} alt='EMAAR Properties' />
                </div>
            </div>
            <div style={styles}>
                <div className="card">
                    <img src={alfattan} alt='Al Fattan Properties' />
                </div>
                </div>
            <div style={styles}>
                <div className="card">
                    <img src={tawazun} alt='Tawazun Properties' />
                </div>
                </div>
            <div style={styles}>
                <div className="card">
                    <img src={adaf} alt='Abu Dhabi Armed Forces' />
                </div>
                </div>
            <div style={styles}>
                <div className="card">
                    <img src={adports} alt='Abu Dhabi Ports' />
                </div>
            </div> 
        </Slider>);
   
};

export default Partners

