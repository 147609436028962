import React from "react";
import generalconst from '../img/general-const.png';
import GenConstIcon from '../img/icons/gen-const-icon.png';
import MEP from '../img/mep.png';
import mepIcon from '../img/icons/mep.png';
import arch from '../img/architecture.png';
import archIcon from '../img/icons/arch-icon.png';
import repair from '../img/surface-repair.png';
import repairIcon from '../img/icons/surface-repair.png';
import fulldepth from '../img/full-depth.png';
import fulldepthIcon from '../img/icons/full-depth-icon.png';
import waterproof from '../img/water-proof.png';
import waterproofIcon from '../img/icons/waterproof-icon.png';
import industrialFlooring from '../img/industrial-flooring.png';
import industrialFlooringIcon from '../img/icons/industrial-flooring-icon.png';
import constTrading from '../img/const-trading.png';
import constTradingIcon from '../img/icons/const-trading-icon.png';
import interior from '../img/interior-design.png';
import interiorIcon from '../img/icons/interior-icon.png';
import Fade from 'react-reveal/Fade';


const Services = () => {

    return(<div className='page-wrapper page-services' id='services'>
        <div className="pages">
            <div className="title-block">
                <Fade left cascade>
                    <span className="wexford-secondary">wexford</span>
                    <span className="span-title">SPECIALITIES<i className="red-dot">.</i></span>
                    <p>We specialize in planning and design and offer a full service in U.A.E from the construction of new spaces, and the renovation of existing ones, right through to high-end interior design production. With us, you can count on a qualified team for high-level architectural planning for furnishing luxurious living spaces.</p>
                </Fade>
            </div>

            <div className="service-content">
                <div className="container">
                    <Fade bottom cascade>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={generalconst} alt='Wexford General Contracting' />
                                <div className="title-bar">
                                    <span className="title">General Construction and<br/>Steel Building</span>
                                    <div className="title-icon">
                                        <img src={GenConstIcon} alt='Wexford General Contracting' />
                                    </div>
                                   
                                </div>
                                <div className="overlay">
                                    <img src={GenConstIcon} alt='General Construction' />
                                    <span className="title-big">General Construction &amp;<br/>Steel Building</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={MEP} alt='Wexford General Contracting' />
                                    <div className="title-bar">
                                        <span className="title">MEP and<br/>Fire Alarm System</span>
                                        <div className="title-icon">
                                           <img src={mepIcon} alt='Wexford General Contracting' />    
                                        </div>
                                    
                                    </div>
                                    <div className="overlay">
                                        <img src={mepIcon} alt='General Construction' />
                                        <span className="title-big">MEP AND<br/>Fire Alarm System</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={arch} alt='Wexford General Contracting' />
                                        <div className="title-bar">
                                            <span className="title">Structural and<br/>Architectural Design</span>
                                            <div className="title-icon">
                                            <img src={archIcon} alt='Wexford General Contracting' />    
                                            </div>
                                        
                                        </div>
                                        <div className="overlay">
                                            <img src={archIcon} alt='General Construction' />
                                            <span className="title-big">Structural &amp; Architectural Design</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Fade>
                    <div className="row">
                        <div className="col-12">
                            <span className="service-category-title">CONCRETE REPAIRS</span>
                        </div>
                    </div>
                    <Fade bottom big cascade>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={repair} alt='Wexford General Contracting' />
                                <div className="title-bar">
                                    <span className="title">Surface<br/>Repairs</span>
                                    <div className="title-icon">
                                        <img src={repairIcon} alt='Wexford General Contracting' />
                                    </div>
                                   
                                </div>
                                <div className="overlay">
                                    <img src={repairIcon} alt='General Construction' />
                                    <span className="title-big">Surface Repairs</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={fulldepth} alt='Full-Depth Replacements' />
                                    <div className="title-bar">
                                        <span className="title">Full-Depth<br/>Replacements</span>
                                        <div className="title-icon">
                                           <img src={fulldepthIcon} alt='Full-Depth Replacements' />    
                                        </div>
                                    
                                    </div>
                                    <div className="overlay">
                                        <img src={fulldepthIcon} alt='Full-Depth Replacements' />
                                        <span className="title-big">Full-Depth Replacements</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={waterproof} alt='Wexford General Contracting' />
                                        <div className="title-bar">
                                            <span className="title">Water<br/>Proofing</span>
                                            <div className="title-icon">
                                            <img src={waterproofIcon} alt='Wexford General Contracting' />    
                                            </div>
                                        
                                        </div>
                                        <div className="overlay">
                                            <img src={waterproofIcon} alt='General Construction' />
                                            <span className="title-big">Water Proofing</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Fade>
                    <Fade bottom big cascade>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={industrialFlooring} alt='Wexford General Contracting' />
                                <div className="title-bar">
                                    <span className="title">Industrial<br/>Flooring</span>
                                    <div className="title-icon">
                                        <img src={industrialFlooringIcon} alt='Wexford General Contracting' />
                                    </div>
                                   
                                </div>
                                <div className="overlay">
                                    <img src={industrialFlooringIcon} alt='General Construction' />
                                    <span className="title-big">Industrial Flooring</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={constTrading} alt='Wexford General Contracting' />
                                    <div className="title-bar">
                                        <span className="title">Construction<br/>Trading</span>
                                        <div className="title-icon">
                                           <img src={constTradingIcon} alt='Wexford General Contracting' />    
                                        </div>
                                    
                                    </div>
                                    <div className="overlay">
                                        <img src={constTradingIcon} alt='General Construction' />
                                        <span className="title-big">Construction Trading</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="service-card">
                                <img src={interior} alt='Wexford General Contracting' />
                                        <div className="title-bar">
                                            <span className="title">Interior<br/>Design</span>
                                            <div className="title-icon">
                                            <img src={interiorIcon} alt='Wexford General Contracting' />    
                                            </div>
                                        
                                        </div>
                                        <div className="overlay">
                                            <img src={interiorIcon} alt='General Construction' />
                                            <span className="title-big">Interior Design</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>

        </div>
        

    </div>)

};

export default Services;