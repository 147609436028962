import React, { useRef } from "react";
import emailjs from '@emailjs/browser';


const Contacts = () => {
  
    const form = useRef();

    const sendInquiries = (e) => {

        e.preventDefault();

        emailjs.sendForm('service_r5pirpo', 'template_czggila', form.current, 'lW5IvNURR-RZ1sq-U')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });


        e.target.reset();


    };

    return(<div className='page-wrapper page-contacts' id='contacts'>
        <div className="pages">
            <div className="contacts-bg">
                <div className="title-block">
                    <span className="subheading">GET IN TOUCH</span>
                    <span className="title">WE'RE HERE TO HELP YOU<i className="red-dot">.</i></span>
                </div>
            </div>
            <div className="map-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <form ref={form} onSubmit={sendInquiries}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="contact-header">Please share us your requirements</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <input type="text" className="form-control boxed" placeholder="Your Name" name="fullname" id="fullname" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <input type="text" className="form-control boxed" placeholder="Company Name" name="company" id="company" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <input type="email" className="form-control boxed" placeholder="Email Address" name="email_address" id="email" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <input id="subject" className="form-control boxed" placeholder="Subject" name="subject" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                           <textarea rows={3} className="form-control boxed" name="message" id="message" placeholder="Your message here"></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <button type="submit" className="btn btn-orange form-control m-10">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3630.610964377356!2d54.40053641208692!3d24.49893287807599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67a52a16039b%3A0x3b49e7595dafcef7!2sAddax%20Office%20Tower!5e0!3m2!1sen!2sae!4v1684827466316!5m2!1sen!2sae"   className="map" loading="lazy" ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)

};

export default Contacts;