import React from "react";

import villas from '../img/icons/villas.png';
import welder from '../img/icons/welder.png';
import buildings from '../img/icons/buildings.png';
import interior from '../img/icons/interior-black.png';
import aluminum from '../img/icons/aluminum.png';
import renovation from '../img/icons/renovation.png';
import maintenance from '../img/icons/maintenance.png';
import Fade from 'react-reveal/Fade';

import CardProjects from "../components/CardProjects";
import Partners from "../components/Partners";

const completed = [
    {
        title: 'Ship Building Workshop',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-ship.png'
    },
    {
        title: 'Store Warehouse',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-wh.png'
    },
    {
        title: 'Maintenance Workshop Showroom',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-holding.png'
    },
    {
        title: 'Restaurant',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-resto.jpeg'
    },
    {
        title: 'EMAAR properties',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/emaar-properties.png'
    },
];

const current = [
    {
        title: 'Ship Building Workshop',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-ship.png'
    },
    {
        title: 'Store Warehouse',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-wh.png'
    },
    {
        title: 'Maintenance Workshop Showroom',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-holding.png'
    },
    {
        title: 'Restaurant',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-resto.jpeg'
    },
    {
        title: 'EMAAR properties',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/emaar-properties.png'
    },
];

const future = [
    {
        title: 'Ship Building Workshop',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-ship.png'
    },
    {
        title: 'Store Warehouse',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-wh.png'
    },
    {
        title: 'Maintenance Workshop Showroom',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-holding.png'
    },
    {
        title: 'Restaurant',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/al-fattan-resto.jpeg'
    },
    {
        title: 'EMAAR properties',
        desc: 'Al Fattan Holding Investment<br/>Al Aseel Engineering Consultant',
        image: '/img/emaar-properties.png'
    },
];

const Projects = () => {

    return(<div className='page-wrapper page-projects' id='projects'>
         <div className="dark-page">
            <div className="title-block">
                <span className="white-span">OUR TOP QUALITY</span>
                <span className="span-title">PROJECTS<i className="red-dot">.</i></span>
            </div>

            <span className="project-headers">COMPLETED / ON-GOING PROJECTS</span>
            <div className="project-container">
                <CardProjects data={completed} />
            </div>
       {/*      <span className="project-headers">ON-GOING</span>
            <div className="project-container">
                <CardProjects data={current} />
            </div> */}

            <span className="project-headers">UPCOMING PROJECTS</span>
            <div className="project-container">
                <CardProjects data={future} />
            </div>

        </div>
        <div className="addon-page-light">
            <Fade left big cascade>
                <div className="title-center">
                    <span className="title-secondary-light">OUR LOYAL</span>
                    <span className="span-title">PARTNERS<i className="red-dot">.</i></span>
                </div>
            </Fade>
                <div className="partners-section">
                    <Partners />
                </div>
            
        </div>
        <div className="addon-page-light">
            <Fade left big cascade>
                <div className="title-center">
                    <span className="title-secondary-light">SUCCESS</span>
                    <span className="span-title">THE NUMBERS SAY<i className="red-dot">.</i></span>
                </div>
            </Fade>
            <div className="container">
               
                    <div className="row">
                        <div className="col-12">
                            <div className="counter-stats">
                                <div className="stats">
                                    <img src={villas} alt=''/>
                                    <div className="counting" data-count="50">50</div>
                                    <h5>VILLAS</h5>
                                </div>
                                <div className="stats">
                                    <img src={welder} alt=''/>
                                    <div className="counting" data-count="15">15</div>
                                    <h5>STEEL WORKS</h5>
                                </div>
                                <div className="stats">
                                    <img src={buildings} alt=''/>
                                    <div className="counting" data-count="3">3</div>
                                    <h5>BUILDINGS</h5>
                                </div>
                                <div className="stats">
                                    <img src={interior} alt=''/>
                                    <div className="counting"  data-count="50">3</div>
                                    <h5>INTERIOR &<br/>FIT OUT</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <div className="counter-stats">
                                <div className="stats">
                                    <img src={aluminum} alt=''/>
                                    <div className="counting" data-count="50">50</div>
                                    <h5>ALUMINUM<br/>WORKS</h5>
                                </div>
                                <div className="stats">
                                    <img src={renovation} alt=''/>
                                    <div className="counting" data-count="16">16</div>
                                    <h5>GENERAL<br/>REHABILITATION</h5>
                                </div>
                                <div className="stats">
                                    <img src={maintenance} alt=''/>
                                    <div className="counting" data-count="16">16</div>
                                    <h5>GENERAL<br/>MAINTENANCE</h5>
                                </div>
                            
                            </div>
                        </div>
                    </div>
               
            </div>
        </div>

    </div>);

};


export default Projects;