import React from "react";
import logo from '../img/wxf-logo-only.png';
import fb from '../img/fb-logo-f.png';
import insta from '../img/insta-logo.png';
import linkedin from '../img/linkedin-logo.png';
import twitter from '../img/twitter-logo.png';
import whatsapp from '../img/whatsapp-logo.png';
import ytube from '../img/ytube.png';
import email from '../img/email-logo.png';

const Footer = () => {

    const wamsg = 'https://wa.me/+971556772419/?text=' + encodeURIComponent('Hi, I would like to know more about your services.');

    return(<div className="footer-wrapper">
        <div className="address-container">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <span className="address-header"><i className='fa fa-map'></i>&nbsp;OFFICE ADDRESS</span>
                        <p>Floor 5712, Addax Tower,<br/>
                            Al Reem Island, Abu Dhabi,<br/>
                            United Arab Emirates
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <span className="address-header"><i className='fa fa-envelope'></i>&nbsp;EMAIL ADDRESS</span>
                        <p>info@wxf.ae</p>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                    <span className="address-header"><i className='fa fa-phone'></i>&nbsp;PHONE NUMBERS</span>
                        <p>+971 55 677 2419 </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="socmed-container">
            <span className="foot-title">FOLLOW US ON OUR SOCIAL MEDIA ACCOUNTS</span>
            <div className="socmed-holder">
                <a href='mailto:info@wxf.ae' target="_blank" className="icons">
                    <img src={email} alt="Wexford Official Email Address" />
                </a>
                <a href='https://www.facebook.com/profile.php?id=100093332565859' target="_blank" className="icons">
                    <img src={fb}  alt='Wexford Facebook Account' />
                </a>
                <a href='https://instagram.com/wexford_contracting?igshid=MzRlODBiNWFlZA==' target="_blank" className="icons">
                    <img src={insta}  alt='Wexford Instagram Account' />
                </a>
                {/* <a href='/' className="icons">
                    <img src={ytube}  alt='Wexford Youtube Account' />
                </a> */}
               {/*  <a href='/' className="icons">
                    <img src={twitter}  alt='Wexford Twitter Account' />
                </a>
                <a href='/' className="icons">
                    <img src={linkedin}  alt='Wexford LinkedIn Account' />
                </a> */}
                <a href={wamsg} className="icons" target="_blank">
                    <img src={whatsapp}  alt='Wexford Whatsapp Account' />
                </a>
            </div>
        </div>
        <div className="foot-note">
            <img src={logo} className="foot-logo" />
            <span className="company-name">WEXFORD GENERAL CONTRACTING &copy; All rights reserved</span>
            <div className="policy">
                <a href='/'>Terms &amp; Conditions</a> | <a href='/'>Privacy Policy</a>
            </div>
        </div>
    </div>);

};

export default Footer;