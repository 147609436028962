import Webpages from "./pages/webpages";

function App() {
  return (
    <div className="App">
        <Webpages />
    </div>
  );
}

export default App;
