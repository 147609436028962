import React, { useState } from 'react';
import '../css/main.scss';
import Logo from '../img/wxf-logo.png';


const Header = () => {

    const[showNavbar, setShowNavbar] = useState(false);

    const  handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    }
   
   return(<div className='header-wrapper'>
           <div className='header-container'>
            <nav className='navigation'>
                    <a href='/' className='brand-name'>
                        <img src={Logo} className='header-logo' alt='Wexford General Contracting' />
                    </a>
                    <div className='hamburger' onClick={handleShowNavbar}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="white"
                                >
                                <path
                                    fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                />
                        </svg>
                    </div>
                    <div className={`navigation-menu ${showNavbar && 'active'}`}>
                        <ul>
                            <li><a href='/' onClick={handleShowNavbar}>HOME</a></li>
                            <li><a href='#about' onClick={handleShowNavbar}>ABOUT US</a></li>
                            <li><a href='#services' onClick={handleShowNavbar}>SERVICES</a></li>
                            <li><a href='#projects' onClick={handleShowNavbar}>PROJECTS</a></li>
                            {/* <li><a href='#team' onClick={handleShowNavbar}>TEAM</a></li> */}
                            <li><a href='#contacts' onClick={handleShowNavbar}>CONTACT US</a></li>
                        </ul>
                    </div>
            </nav>
           </div>
    </div>);

};

export default Header;





